<template>
  <div class="product_combo">
    <div class="container product_combo_container">
      <div class="row product_combo_row">
        <div v-if="!isInlineCard" class="col-12 d-none d-lg-block">
          <div class="product_combo_breadcrumbs">
            <Breadcrumbs
              :parentCategory="parentCategoryToBreadcrumbs"
              :currentName="getTitle"
              type="menuItem"
            />
          </div>
        </div>
        <div class="col-5 d-none d-lg-block product_combo_col">
          <ComboSlider :items="item.bundleItems" />
        </div>
        <div class="col-12 col-lg-7 product_combo_col">
          <div class="product_combo_info">
            <div class="product_combo_info_photo">
              <ProductPhoto
                :photo="getPhoto"
                :attrs="getStickers"
                :isDoubling="isDoubling"
              />
            </div>
            <div class="product_combo_info_inner">
              <div class="product_combo_info_wrapper">
                <div class="product_combo_title">
                  <h1>{{ getTitle }}</h1>
                </div>
                <div class="product_combo_description">
                  <p>{{ getDescription }}</p>
                </div>
                <div class="product_combo_slider">
                  <ComboSliderMobile :items="item.bundleItems" />
                </div>
                <div class="product_combo_attrs">
                  <div v-if="getTechnicalCard.weight" class="product_combo_attrs_item">
                    <span>Вес</span>
                    <strong>
                      <icon-weight />
                      {{ getTechnicalCard.weight }}г
                    </strong>
                  </div>
                  <div v-if="getTechnicalCard.proteins" class="product_combo_attrs_item">
                    <span>Белки</span>
                    <strong>{{ getTechnicalCard.proteins }}г</strong>
                  </div>
                  <div v-if="getTechnicalCard.fats" class="product_combo_attrs_item">
                    <span>Жиры</span>
                    <strong>{{ getTechnicalCard.fats }}г</strong>
                  </div>
                  <div v-if="getTechnicalCard.carbohydrates" class="product_combo_attrs_item">
                    <span>Углеводы</span>
                    <strong>{{ getTechnicalCard.carbohydrates }}г</strong>
                  </div>
                  <div v-if="getTechnicalCard.calories" class="product_combo_attrs_item">
                    <span>Калорийность</span>
                    <strong>{{ getTechnicalCard.calories }}кКал</strong>
                  </div>
                </div>
                <div class="product_combo_submit">
                  <div class="product_combo_submit_price">
                    <div class="product_combo_submit_price_inner">
                      <del v-if="getOldPrice">{{ getOldPrice }}<Ruble /></del>
                      <strong>{{ getPrice }}<Ruble /></strong>
                    </div>
                    <div v-if="getBonus" class="product_combo_submit_price_bonus">
                      <img src="/setcoins-icon.svg" alt="">
                      <div>
                        <span>ваш бонус</span>
                        <br>
                        <strong>+{{ getBonus }} <Ruble /></strong>
                      </div>
                    </div>
                  </div>
                  <div class="product_combo_submit_incart">
                    <Button @click="remoteCartPush" class="button_border">
                      <font-awesome-icon :icon="['fas', 'shopping-basket']" />В корзину
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container d-none d-lg-block" v-if="!isInlineCard">
			<div class="row">
				<div class="col-12" style="margin-bottom: 35px">
					<div class="product_combo_recomended_title">
						<InnerTitle>
							<template v-slot:title>Рекомендуем попробовать</template>
						</InnerTitle>
					</div>
				</div>
				<div class="col-12">
					<div class="product_combo_recomended">
						<RecomendedSlider />
					</div>
				</div>
			</div>
		</div> -->
  </div>
</template>

<script>
import RecomendedSlider from '@/components/page/RecomendedSlider'
import InnerTitle from '@/components/UI/InnerTitle'
import ComboSliderMobile from '@/components/page/ComboSliderMobile'
import global from '@/mixins/global'
import ComboSlider from './../ComboSlider'
import ProductPhoto from './ProductPhoto'
import Breadcrumbs from './../../UI/Breadcrumbs'
import Ruble from './../../UI/Ruble'
import Button from './../../UI/Button'

export default {
  components: { ComboSlider, ProductPhoto, Ruble, Breadcrumbs, Button, RecomendedSlider, InnerTitle, ComboSliderMobile },
  mixins: [global],
  props: {
    item: {
      type: Object,
      default () {
        return null
      },
    },
    isInlineCard: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  computed: {
    parentCategoryToBreadcrumbs () {
      return this.item && this.item.categories && this.item.categories[0]
        ? { name: this.item.categories[0].name, path: `/menu/${this.item.categories[0].slug}` }
        : {}
    },
    getUuid () { return this.item ? this.item.id : null },
    getBonus () {
      return this.item
        ? this.item.foxCoinsAdd
        : 0
    },
    getType () { return this.item ? this.item.type : null },
    getTitle () { return this.item ? this.item.name : null },
    getDescription () { return this.item ? this.item.description : null },
    getConsist () { return this.item ? this.item.composition : null },
    getPhoto () { return this.item ? this.item.image : null },
    getPrice () { return this.item ? this.item.price : null },
    getModifiedPrice () {
      const IS_MODIFY = this.modifications.length ? this.modifications[0].active : false

      return IS_MODIFY ? this.modifications[0].price : this.getPrice
    },
    getOldPrice () { return this.item ? this.item.oldPrice : null },
    getStickerType () { return this.item ? this.item.stickerType : '' },
    getStickerMarketing () { return this.item ? this.item.stickerMarketing : '' },
    getStickers () {
      return {
        type: this.getStickerType,
        marketing: this.getStickerMarketing,
      }
    },
    getModifications () { return this.item ? this.item.modifications : [] },
    getTechnicalCard () { return this.item ? this.item.technicalCard : null },
	  isDoubling () { return this.item && this.item.isDoubling },
  },
  methods: {
    remoteCartPush () {
      this.addToCart(
        this.item,
        false, // Модификаторов у комбо нет, так шо отправляем false
      )
      this.$nuxt.$emit('close-modal')
    },
  },
}
</script>

<style lang="less">
.product_combo {
	width: 100%;

	@media screen and (max-width: 768px) {
		&_row {
			margin-right: 0;
			margin-left: 0;
		}

		&_col {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&_container {
		margin-bottom: 60px;

		@media screen and (max-width: 768px) {
			max-width: 100%;
			display: flex;
			padding-right: 0;
			padding-left: 0;
		}
	}

	&_breadcrumbs {
		padding-top: 13px;
		padding-bottom: 27px;

		@media screen and (max-width: 992px) { display: none; }
	}

	&_info {
		&_photo {
			position: relative;

			@media screen and (max-width: 768px) { background-color: #f6f6f6; }

			.product {
				&_photo {
					height: 444px;
					box-sizing: border-box;
					overflow: visible;

					@media screen and (max-width: 768px) {
						padding-top: 14px;
						padding-bottom: 20px;
					}
				}

				&_like {
					top: unset;
					bottom: -17px;
					right: 26px;
				}
			}
		}

		&_inner {
			background-color: white;
			padding-top: 21px;
			padding-left: 35px;

			@media screen and (max-width: 768px) {
				background-color: #f6f6f6;
				padding: 0;
			}
		}

		&_wrapper {
			@media screen and (max-width: 768px) {
				background-color: white;
				padding: 35px 22px 0 22px;
				border-top-left-radius: 30px;
				border-top-right-radius: 30px;
			}
		}
	}

	&_title {
		margin-bottom: 30px;

		@media screen and (max-width: 1200px) { margin-bottom: 17px; }

		p, h1 {
			font-family: 'Roboto Slab', Arial, sans-serif;
			font-weight: 500;
			font-size: 25px;
			line-height: 30px;

			@media screen and (max-width: 576px) { font-size: 20px; }
		}
	}

	&_description {
		margin-bottom: 30px;

		@media screen and (max-width: 1200px) { min-height: unset; }

		p {
			font-family: 'Roboto', Arial, sans-serif;
			font-weight: 300;
			font-size: 14px;
			line-height: 18px;
		}
	}

	&_slider {
		display: none;
		padding-bottom: 23px;

		@media screen and (max-width: 768px) {
			display: block;
		}
	}

	&_attrs {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		margin-bottom: 25px;

		@media screen and (max-width: 1200px) { margin-bottom: 15px; }

		&_item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			background-color: #F6F6F6;
			font-family: 'Roboto', Arial, sans-serif;
			padding: 8px 13px 13px 13px;
			border-radius: 15px;
			margin-bottom: 10px;

			&:not(:last-child) {
				margin-right: 10px;
			}

			span {
				font-size: 12px;
				line-height: 18px;
				text-align: center;
				margin-bottom: 8px;
			}

			strong {
				font-weight: bold;
				font-size: 12px;
				text-align: center;

				svg {
					font-size: 9px;
					margin-right: 4px;
				}
			}
		}
	}

	&_submit {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&_price {
			display: flex;
			align-items: center;
			flex-shrink: 0;

			&_inner {
				position: relative;
				margin-right: 30px;
		  	margin-top: 3px;

				del {
					font-family: 'Roboto', Arial, sans-serif;
					font-size: 16px;
					color: #B7B7B7;
					position: absolute;
					top: -15px;
				}

				strong {
					font-family: 'Roboto', Arial, sans-serif;
					font-weight: 500;
					font-size: 30px;
					color: black;
				}
			}

			&_bonus {
				display: flex;
				align-items: center;
				font-family: 'Roboto', Arial, sans-serif;

				@media screen and (max-width: 768px) { flex-direction: column; }

				img {
					width: 27px;
					height: 27px;
					object-fit: contain;
					object-position: center;
					margin-right: 12px;

					@media screen and (max-width: 768px) { margin-right: 0; }
				}

				span {
					font-weight: 300;
					font-size: 12px;

					@media screen and (max-width: 768px) { display: none; }
				}

				strong {
					font-weight: 500;
					font-size: 14px;
				}
			}
		}
	}

	&_recomended {
		.swiper {
			&-container {
				padding-bottom: 72px;
			}

			&-pagination {
				bottom: 27px;
			}
		}

		&_title {
			margin-bottom: 67px;
		}
	}
}
</style>
