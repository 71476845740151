<template>
  <div class="combo_slider_card">
    <div class="combo_slider_card_inner">
      <div class="combo_slider_card_image">
        <img :src="getMenuItem.image" alt="">
        <!-- TODO: Модификатор половинка -->
        <!-- <img src="/cardicons/halfsmall.svg" class="combo_slider_card_image_half"> -->
      </div>
      <div class="combo_slider_card_info">
        <strong>{{ getMenuItem.name }}</strong>
        <p>{{ getMenuItem.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getMenuItem () { return this.item.menuItem },
  },
}
</script>

<style lang="less">
.combo_slider_card {
	width: 100%;
	height: 166px;
	display: flex;
	background-color: white;
	border-radius: 15px;
	box-shadow: 0 3px 12px rgba(183, 183, 183, .48);
	box-sizing: border-box;

	&_inner {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding: 15px;
		box-sizing: border-box;
		position: relative;
	}

	&_image {
		width: 135px;
		display: flex;
		align-items: center;
		margin-right: 25px;
		flex-shrink: 0;

		img {
			width: 100%;
			height: auto;
		}

		&_half {
			width: 33px !important;
			height: 33px !important;
			position: absolute;
			top: 11px;
			left: 11px;
		}
	}

	&_info {
		strong {
			font-family: 'Roboto Slab', Arial, sans-serif;
			font-weight: 500;
			font-size: 16px;
			margin-bottom: 14px;
		}

		p {
			max-height: 70px;
			font-family: 'Roboto', Arial, sans-serif;
			font-weight: 300;
			font-size: 12px;
			line-height: 18px;
			overflow: hidden;
		}
	}
}
</style>
