<template>
  <div class="combo_slider">
    <perfect-scrollbar>
      <div class="combo_slider_list">
        <ComboSliderCard
          v-for="(val, i) in items"
          :item="val"
          :key="`csloder-combo-${ i }`"
        />
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import ComboSliderCard from './ComboSliderCard'

export default {
  components: { ComboSliderCard },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="less">
.combo_slider {
	position: relative;

	&:after {
		content: '';
		width: 100%;
		height: 17px;
		background: transparent;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(128,128,128,0.13) 100%);
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.ps {
		height: 760px;
		padding-top: 10px;
		padding-left: 28px;
		padding-right: 34px;
		margin-top: -10px;

		@media screen and (max-width: 1200px) {
			padding-left: 0;
			padding-right: 25px;
		}

		&__rail-y {
			width: 10px;
			background-color: #F6F6F6;
			border-radius: 10px;
			opacity: 1 !important;
		}

		&__thumb-y {
			width: 10px;
			background: rgb(255,152,0);
			background: linear-gradient(180deg, rgba(255,152,0,1) 0%, rgba(255,78,0,1) 100%);
			border-radius: 10px;
		}
	}

	&_list {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	&_card {
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 18px;
		}
	}
}
</style>
