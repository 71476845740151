<template>
  <div class="combo_slider_mobile">
    <div v-if="!activeItem" class="combo_slider_mobile_wrap">
      <perfect-scrollbar>
        <div class="combo_slider_mobile_list">
          <div
            v-for="(val, i) in items"
            :key="`csmvl-${ i }`"
            @click="changeActive(i)"
            class="combo_slider_mobile_item"
          >
            <img :src="val.menuItem.image" alt="">
            <p>{{ val.menuItem.name }}</p>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div v-else @click="closeActiveItem" class="combo_slider_mobile_active">
      <div class="combo_slider_mobile_item">
        <img :src="activeItem.menuItem.image" alt="">
        <p>{{ activeItem.menuItem.name }}</p>
      </div>
      <div class="combo_slider_mobile_info">
        <p>{{ activeItem.menuItem.description }}</p>
        <span @click="closeActiveItem" class="combo_slider_mobile_active_close">
          <font-awesome-icon :icon="['fas', 'times']" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      activeItem: null,
    }
  },
  methods: {
    changeActive (index) {
      this.activeItem = this.items[index]
    },
    closeActiveItem () {
      this.activeItem = null
    },
  },
}
</script>

<style lang="less">
	.combo_slider {
		&_mobile {
			width: 100%;
			display: flex;

			&_wrap {
				position: relative;

				&:after {
					content: '';
					width: 35px;
					height: 100%;
					background: transparent;
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
					position: absolute;
					top: 0;
					right: 0;
				}
			}

			&_list {
				display: flex;
				align-items: stretch;
			}

			&_item {
				min-height: 90px;
				width: 90px;
				flex-grow: 0;
				flex-shrink: 0;
				background-color: white;
				padding: 8px 9px;
				border-radius: 15px;
				box-shadow: 0 3px 12px rgba(183, 183, 183, .48);
				box-sizing: border-box;

				&:not(:last-child) { margin-right: 9px; }

				img {
					width: 100%;
					height: 51px;
					object-fit: contain;
					object-position: center;
				}

				p {
					font-family: 'Roboto', Arial, sans-serif;
					font-size: 10px;
					text-align: center;
					line-height: 10px;
				}
			}

			&_active {
				display: flex;
				align-items: stretch;
				position: relative;

				&_close {
					font-size: 8px;
					position: absolute;
					top: 7px;
					right: 12px;
					cursor: pointer;
				}

				.combo_slider_mobile_item {
					flex-grow: 0;
					flex-shrink: 0;
					margin-right: 10px;
				}
			}

			&_info {
				display: flex;
				align-items: center;
				flex-grow: 1;
				background-color: #F6F6F6;
				border-radius: 15px;

				p {
					height: 48px;
					display: block;
					font-family: 'Roboto', Arial, sans-serif;
					font-size: 10px;
					line-height: 14px;
				}
			}

			.ps {
				width: calc(100vw - 44px);
				padding: 10px 0 15px 10px;
				margin: -10px 0 -15px -10px;
			}
		}
	}
</style>
